import React, { useState, useEffect } from "react";
import "../styles/home.scss";
import ZoomableImage from "../components/zoomableImage";
import ArtworkService from '../apis/ArtworkService';
import "../styles/city.scss";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Link } from 'react-router-dom';
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const queryClient = new QueryClient();
const artworkObject = new ArtworkService();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

// Image preloader function
const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

export default function Home() {
  return (
    <div className="page_container">
      <QueryClientProvider client={queryClient}>
        <GetHomePageData />
      </QueryClientProvider>
    </div>
  );
}

function GetHomePageData() {
  const [paintings, setPaintings] = useState([]);
  const [show, setShow] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const { isLoading, error, data } = useQuery("repoData", async () => {
    const result = await artworkObject.getPortfolioPage();
    return result;
  });

  // Preload images when data is available
  useEffect(() => {
    if (data) {
      setPaintings(data);
      // Preload all images
      data.forEach(painting => {
        const imgUrl = `https://arg-paintings.s3.us-east-2.amazonaws.com/${painting.fileName}`;
        preloadImage(imgUrl);
      });
    }
  }, [data]);

  // Handle image navigation
  const handleNavigation = async (direction) => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    let nextIndex;
    if (direction === 'next') {
      nextIndex = show === paintings.length - 1 ? 0 : show + 1;
    } else {
      nextIndex = show === 0 ? paintings.length - 1 : show - 1;
    }

    // Preload the next image
    const nextImageUrl = `https://arg-paintings.s3.us-east-2.amazonaws.com/${paintings[nextIndex].fileName}`;
    await preloadImage(nextImageUrl);
    
    setShow(nextIndex);
    setIsTransitioning(false);
  };

  if (isLoading || paintings.length === 0) {
    return (
      <div className="loader-container">
        <MoonLoader loading={isLoading} css={override} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <h1>Error, please contact support.</h1>
      </div>
    );
  }

  return (
    <div className="gallery-container">
      <div className="header">
        <h1 className="titles">Alan Robert Garry</h1>
      </div>
      
      <div className="carousel-wrapper">
        <div className="navigation-button">
          <ArrowBackIosIcon
            onClick={() => handleNavigation('prev')}
            className="nav-arrow"
          />
        </div>
        
        <div className="image-container">
          <ZoomableImage
            style="city-img"
            img_location={`https://arg-paintings.s3.us-east-2.amazonaws.com/${paintings[show].fileName}`}
            alt_text={paintings[show].title}
          />
        </div>
        
        <div className="navigation-button">
          <ArrowForwardIosIcon
            onClick={() => handleNavigation('next')}
            className="nav-arrow"
          />
        </div>
      </div>

      <div className="attribute-container">
        <div className="title">{paintings[show].title}</div>
        <div className="dimensions">
          {paintings[show].height} x {paintings[show].width}
        </div>
        <div className="comment">{paintings[show].comment}</div>
      </div>

      <div className="create-link">
        <Link to="/create">Admin</Link>
      </div>
    </div>
  );
}