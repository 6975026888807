import React, { useState } from "react";
import "../styles/create.scss";

import MoonLoader from "react-spinners/MoonLoader";

import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArtworkService from "../apis/ArtworkService";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { produce } from "immer";

const artworkObject = new ArtworkService();
const queryClient = new QueryClient();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export default function Create() {
  return (
    <div className="page_container">
      <QueryClientProvider client={queryClient}>
        <GetData />
      </QueryClientProvider>
    </div>
  );
}

function GetData() {
  const [paintings, setPaintings] = useState([]);
  const [title, setTitle] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [fileName, setFileName] = useState("");
  const [comment, setComment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [adminFunction, setAdminFunction] = useState(true);

  const { isLoading, error, data } = useQuery("repoData", async () => {
    const data = await artworkObject.getHomePage();

    if (data) {
      setPaintings(data);
    }
  });

  if (error) {
    return (
      <div>
        <MoonLoader loading={isLoading} css={override} size={50} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <MoonLoader loading={isLoading} css={override} size={50} />
      </div>
    );
  }

  const notify = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: false,
    });
  };

  const notifyBug = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: false,
    });
  };

  const notifyError = () => {
    toast.error("Please Check Missing Fields or contact Ethan.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: false,
      progress: undefined,
    });
  };

  const handleFileInput = (e) => setSelectedFile(e.target.files[0]);

  async function deletePainting(id) {
    const res = await artworkObject.deleteArt(id);
    if (res === false) {
      notifyError(
        "Painting not deleted. Please try again or contact system admin."
      );
    } else {
      notify("Successfully Deleted Artwork");
      setPaintings(res);
    }
  }

  function clearFields() {
    setTitle("");
    setWidth("");
    setHeight("");
    setFileName("");
    setComment("");
    setSelectedFile(null);
  }

  async function addPainting(e) {
    e.preventDefault();

    var triggerUpload = true;

    if (triggerUpload) {
      const data = {
        title: title,
        comment: comment,
        width: width,
        height: height,
        fileName: fileName,
      };

      const res = await artworkObject.uploadNewArt(selectedFile, data);
      if (res) {
        notify("Successfully Added Artwork");
        clearFields();
      } else {
        notifyError();
      }
    }
  }

  async function editPainting(body) {
    // console.log(body)

    const res = await artworkObject.editArt(body);

    if (res) {
      // alert('Successfully Edited Artwork')
      notify("Successfully Edited Artwork");
    } else {
      notifyError();
    }
  }

  if (adminFunction) {
    return (
      <>
        <div className="admin-function">
          <button
            onClick={() => {
              setAdminFunction(false);
            }}
          >
            Edit Paintings
          </button>
        </div>
        <ToastContainer limit={1}></ToastContainer>
        <div className="page_container">
          <div className="header">
            <h1 className="contact-title">Add a Painting</h1>
          </div>
          <form onSubmit={addPainting} autoComplete="off">
            <div className="form_container">
              <div className="form_inputs">
                <input
                  className="file-input"
                  type="file"
                  onChange={handleFileInput}
                />

                <input
                  name="title"
                  type="text"
                  placeholder="title"
                  value={title}
                  onChange={(n) => {
                    setTitle(n.target.value);
                  }}
                />
                <input
                  name="width"
                  type="number"
                  placeholder="width"
                  value={width}
                  onChange={(n) => {
                    setWidth(n.target.value);
                  }}
                />
                <input
                  name="height"
                  type="number"
                  placeholder="height"
                  value={height}
                  onChange={(n) => {
                    setHeight(n.target.value);
                  }}
                />
                <input
                  name="comment"
                  type="text"
                  placeholder="comment"
                  value={comment}
                  onChange={(n) => {
                    setComment(n.target.value);
                  }}
                />
              </div>
            </div>
            <div className="submit_button">
              <input type="submit" value="Add" />
            </div>
          </form>
        </div>
      </>
    );
  } else {
    return (
      <>
        <ToastContainer limit={1}></ToastContainer>
        <div className="admin-function">
          <button
            onClick={() => {
              setAdminFunction(true);
            }}
          >
            Add a Painting
          </button>
        </div>
        <div className="edit-paintings-container">
          {paintings.map((elem, indx) => (
            <div key={elem["id"]}>
              <div>
              <div>
              <label>Title</label>
                <input
                  onChange={(e) => {
                    const title = e.target.value;
                    setPaintings((currentPaintings) =>
                      produce(currentPaintings, (v) => {
                        v[indx].title = title;
                      })
                    );
                  }}
                  value={elem["title"]}
                  placeholder={elem["title"]}
                />
              </div>
              </div>
              <img
                className="img-edit"
                src={
                  "https://arg-paintings.s3.us-east-2.amazonaws.com/" +
                  elem["fileName"]
                }
                alt={elem["title"]}
              />
              <div>
                <div>
                  <label>Comment</label>
                  <textarea
                    onChange={(e) => {
                      const comment = e.target.value;
                      setPaintings((currentPaintings) =>
                        produce(currentPaintings, (v) => {
                          v[indx].comment = comment;
                        })
                      );
                    }}
                    value={elem["comment"]}
                    placeholder={elem["comment"]}
                  />
                </div>

                <button
                  className="edit-painting-button"
                  onClick={() => {
                    editPainting(elem);
                  }}
                >
                  Save Edits
                </button>

                <button
                  className="delete-painting"
                  onClick={() => {
                    deletePainting(elem["id"]);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
